import React, {useState, useEffect, useContext} from "react";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { withRouter } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import NotFound from "./pages/not-found/not-found.component";
import LoginPage from "./pages/login/login.component";
import FloorsPage from "./pages/floors/floors.component";
import SpaceInfo from "./pages/space-info/space-info.component";
import Floor from "./pages/floor/floor.component";
import Area from "./pages/area/area.component";
import TimeTable from "./pages/timetable/timetable.component";
import NotificationPage from "./pages/notifications/notification.component";
import UserNotifications from "./pages/user-notifications/user-notifications.component";
import ManageSystem from "./pages/manage-system/manage-system.component";
import ManagePermissions from "./pages/manage-permissions/manage-permissions.component";
import EditSpacePage from "./pages/edit-space/edit-space.component";
import SystemInfo from "./pages/system-info/system-info.component";
import FilterSpacePage from "./pages/filter-space/filter-space.component";
import Reports from "./pages/reports/reports.component";
import DeleteReservation from "./pages/delete-reservation/delete-reservation.component";
import ManageReservationReason from "./pages/manage-reservation-reason/manage-reservation-reason.component";
import ConsultSpaceManager from "./pages/consult-space-manager/consult-space-manager.component";

import "./App.scss";

import NavbarCustom from "./components/navbar/navbar.component";

import API_ROUTE from "./utils/apiRoute";
import { GlobalContext } from "./GlobalState";
import ProfilePage from "./pages/profile/profile.component";

const App = ({ history }) => {
  const dispatch = useDispatch();
  const employeeName = useSelector((state) => state.employee.employeeName);
  const employeeRole = useSelector((state) => state.employee.employeeRole);

  const [systemMasterManager, setSystemMasterManager] = useState(false);
  const [systemManager, setSystemManager] = useState(false);
  const [spaceManager, setSpaceManager] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");
  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);
  const { setUserSchoolCode } = useContext(GlobalContext);

  useEffect(() => {
    if (employeeRole === "system-master-manager") {
      setSystemMasterManager(true);
    }
    if (employeeRole === "system-manager") {
      setSystemManager(true);
    }
    if (employeeRole === "space-manager") {
      setSpaceManager(true);
    }
    if (employeeRole === "user") {
      setIsUser(true);
    }
  }, [employeeRole]);

  const logout = () => {
    (async () => {
      await axios
        .get(`${API_ROUTE}employee/logout`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setIsAuthenticated(false);
            setSystemMasterManager(false)
            setSystemManager(false);
            setSpaceManager(false);
            setIsUser(false);
            setSelectedSchoolCode(0);
            setUserSchoolCode(0);
            localStorage.removeItem('savedSchoolCode');
            history.push("/");
          }
        })
        .catch((err) => {
          alert("Something went wrong while logging out. Try Again.");
        });
    })();
  };

  const setAuthenticated = () => {
    setIsAuthenticated((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(`${API_ROUTE}employee`, {
          withCredentials: true,
        });

        const { role, name, schoolCode } = response.data.user;

        setIsAuthenticated(true);
        dispatch({ type: "SET_EMPLOYEE_ROLE", payload: role });
        dispatch({ type: "SET_EMPLOYEE_NAME", payload: name });
        setUserName(name);

        let savedSchoolCode = localStorage.getItem('savedSchoolCode');

        if (schoolCode) {
          if (!savedSchoolCode) {
            setSelectedSchoolCode(schoolCode);
            localStorage.setItem('savedSchoolCode', selectedSchoolCode.toString());
          }
          setUserSchoolCode(schoolCode);
        }
        else {
          const schoolResponse = await axios.get(`${API_ROUTE}school`, {
            withCredentials: true,
          });
          if (!savedSchoolCode) {
            setSelectedSchoolCode(schoolResponse.data.data.allSchools[0].code);
            localStorage.setItem('savedSchoolCode', selectedSchoolCode.toString());
          }
          setUserSchoolCode(schoolResponse.data.data.allSchools[0].code);
        }

        savedSchoolCode = localStorage.getItem('savedSchoolCode');
        setSelectedSchoolCode(Number(savedSchoolCode));

      } catch (error) {
        console.error('Failed to fetch employee data:', error);
      }
    };

    fetchEmployeeData();
  }, []);

  const DefaultRoutes = () => (
    <div className="App">
      <NavbarCustom
        isAuthenticated={isAuthenticated}
        systemMasterManager={systemMasterManager}
        systemManager={systemManager}
        spaceManager={spaceManager}
        employeeName={employeeName}
        logout={logout}
      />

      <div className="content">
        <Switch>
          {isAuthenticated ? (
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
          ) : (
            <Route
              exact
              path="/"
              component={() => (
                <LoginPage
                  setAuthenticated={setAuthenticated}
                  setUserName={setUserName}
                  setSystemMasterManager={setSystemMasterManager}
                  setSystemManager={setSystemManager}
                  setSpaceManager={setSpaceManager}
                />
              )}
            />
          )}

          {/* <Route exact path="/profile" component={() => <RegisterUser />} /> */}

          <Route
            exact
            path="/home"
            component={() => <FilterSpacePage />}
          />
          <Route
            exact
            path="/notifications"
            component={() =>
              (spaceManager || systemManager || systemMasterManager)
                ? <NotificationPage isSystemMasterManager={systemMasterManager}/> : (isUser ? <UserNotifications/> : null)
            }
          />
          <Route
            exact
            path="/manage-system"
            component={
              (systemManager || systemMasterManager) ? ManageSystem : spaceManager ? EditSpacePage : null
            }
          />
          <Route
            exact
            path="/permissions"
            component={(systemManager || systemMasterManager) ? ManagePermissions : null}
          />

          <Route
            exact
            path="/spreadsheets"
            component={(spaceManager) ? Reports : null}
          />

          <Route
            exact
            path="/delete-reservation"
            component={(systemMasterManager) ? DeleteReservation : null}
          />

          <Route
            exact
            path="/reservationReasons"
            component={(systemManager || systemMasterManager) ? ManageReservationReason : null}
          />

          <Route
            exact
            path="/myspaces"
            component={(systemManager || systemMasterManager) ? ManageReservationReason : null}
            component={
              systemManager || systemMasterManager || spaceManager
                ? () => <ConsultSpaceManager isAdmin={(systemManager || systemMasterManager)} />
                : null
            }
          />

          { isAuthenticated ? (
            <Route
                exact
                path="/profile"
                component={ () => <ProfilePage /> }
            />
            ) : null }

          {/* <Route exact path="/edit-space" component={EditSpacePage} /> */}
          <Route exact path="/findFloor" component={FloorsPage} />
          <Route exact path="/info" component={SystemInfo} />
          <Route exact path="/floor/:floorId" component={Floor} />
          <Route exact path="/floor/:floorId/area/:areaId" component={Area} />
          <Route
            exact
            path="/floor/:floorId/area/:areaId/spaceinfo/:spaceinfoId"
            component={SpaceInfo}
          />
          <Route
            exact
            path="/floor/:floorId/area/:areaId/timetable/:timetableId"
            component={TimeTable}
          />

          {/*<Route*/}
          {/*  path="*"*/}
          {/*  component={() => {*/}
          {/*    return <Redirect to="/notfound" />;*/}
          {/*  }}*/}
          {/*/>*/}

        </Switch>
      </div>
    </div>
  );

  return (
    <Switch>
      <Route component={NotFound} path="/notfound" />
      <Route component={DefaultRoutes} />
    </Switch>
  );
};

export default withRouter(App);

