import React from "react";
import "./with-spinner.styles.scss";

const WithSpinner = WrappedComponent => {
  return ({isLoading, ...otherProps}) => {
    return isLoading ? (
        <div className="spinner-overlay">
          <div className="spinner-container-overlay"></div>
        </div>
    ) : (
        <WrappedComponent {...otherProps} />
    );
  };
};

export default WithSpinner;
