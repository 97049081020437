import React, { useState, useEffect } from "react";
import {Spinner, Toast} from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import axios from "axios";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal.component";
import "./user-notifications.styles.scss";

import API_ROUTE from "../../utils/apiRoute";
import useModal from "../../utils/useModal";

const UserNotifications = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [solicitationType, setSolicitationType] = useState("em análise");
  const [reservationData, setReservationData] = useState([]);
  const [arrayOfNotifications, setArrayOfNotifications] = useState([]);

  const [selectedId, setSelectedId] = useState(null);

  const {
    isShowing: isShowingConfirmationModal,
    toggle: toggleConfirmationModal,
  } = useModal();

  const cancelReservation = () => {
    (async () => {
      await axios
        .patch(`${API_ROUTE}reservation/${selectedId}`, null, {
          withCredentials: true,
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          alert("Algo deu errado, tente novamente.");
          alert(JSON.stringify(err, null, 2));
        });
    })();
  };

  useEffect(() => {
    let didCancel = false;
    (async () => {
      await axios
        .get(`${API_ROUTE}reservation`, {
          withCredentials: true,
        })
        .then((res) => {
          if (didCancel) return;
          setReservationData(res.data.data.reservationData);
          setIsLoading(false)
        });
    })();

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatHour = (time) => {
    let formattedTime;
    formattedTime = new Date(time).toUTCString().split(" ")[4].split(":");
    formattedTime.pop();

    return formattedTime[0] + ":" + formattedTime[1];
  };

  const createToasts = (unformatedReservationData) => {
    unformatedReservationData.sort(
      (a, b) =>
        new Date(new Date(b.requestDate)) - new Date(new Date(a.requestDate))
    );

    const tmpArr = unformatedReservationData.map((val) => {
      let elapsedTime = Math.abs(Date.now() - +new Date(val.requestDate));
      if (elapsedTime / 1000 / 60 / 60 > 1) {
        elapsedTime =
          parseInt(elapsedTime / 1000 / 60 / 60).toString() +
          `${
            parseInt(elapsedTime / 1000 / 60 / 60) === 1
              ? " hora atrás"
              : " horas atrás"
          }`;
      } else if (elapsedTime / 1000 / 60 < 1) {
        elapsedTime =
          parseInt(elapsedTime / 1000).toString() + ` segundos atrás`;
      } else {
        elapsedTime =
          parseInt(elapsedTime / 1000 / 60).toString() +
          `${
            parseInt(elapsedTime / 1000 / 60) === 1
              ? " minuto atrás"
              : " minutos atrás"
          }`;
      }

      const formattedTimeStart = formatHour(val.timeStart);

      const formattedTimeEnd = formatHour(val.timeEnd);

      return (
        <Toast
          onClose={() => {
            setSelectedId(val._id);
            toggleConfirmationModal();
          }}
          className="notification-toast"
        >
          <Toast.Header
            className="notification-toast--header"
            closeButton={false}
          >
            <strong className="mr-auto">{val.spaceName}</strong>
            <small>{elapsedTime}</small>
            {solicitationType === "em análise" ||
            solicitationType === "reservado" ? (
              <FaRegTrashAlt
                className={"user_notification-icon"}
                onClick={() => {
                  setSelectedId(val._id);
                  toggleConfirmationModal();
                }}
              />
            ) : null}
          </Toast.Header>

          <Toast.Body>
            <strong>Solicitante:</strong> {val.requester}
            <br />
            <strong>Motivo:</strong> {val.reason}
            <br />
            <strong>Descrição:</strong> {val.description}
            <br />
            <strong>Data: </strong>
            {new Date(val.timeStart).toLocaleDateString()} <br />
            <strong>Período de Início: </strong>
            {formattedTimeStart}
            <br />
            <strong>Período de Fim: </strong> &nbsp;&nbsp;&nbsp;
            {formattedTimeEnd}
            {solicitationType === "rejeitado" ? (
              <>
                <br /> <strong>Justificativa da Análise: </strong>{" "}
                {val.analysisJustification}{" "}
              </>
            ) : null}
            <br />
          </Toast.Body>
        </Toast>
      );
    });

    setArrayOfNotifications(React.Children.toArray(tmpArr));
  };

  useEffect(() => {
    const analysisReservation = reservationData.filter(
      (val) => val.status === "em análise"
    );
    createToasts(analysisReservation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationData]);

  useEffect(() => {
    const filteredArray = reservationData.filter(
      (val) => val.status === solicitationType
    );
    createToasts(filteredArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitationType]);

  const handleClick = (solicitationType) => {
    setSolicitationType(solicitationType);
  };

  const spinn = <Spinner animation="border" />;

  return (
    <div className="user-notification-container">
      <div className="user-notification-side-bar">
        <div className="wrapper-links">
          <a
            className={`user-notification-side-bar--buttons ${
              solicitationType === "em análise"
                ? "user-notification-side-bar--buttons---active"
                : null
            }`}
            onClick={() => handleClick("em análise")}
          >
            Solicitações Pendentes
          </a>
          <a
            className={`user-notification-side-bar--buttons ${
              solicitationType === "rejeitado"
                ? "user-notification-side-bar--buttons---active"
                : null
            }`}
            onClick={() => handleClick("rejeitado")}
          >
            Solicitações Recusadas
          </a>
          <a
            className={`user-notification-side-bar--buttons ${
              solicitationType === "reservado"
                ? "user-notification-side-bar--buttons---active"
                : null
            }`}
            onClick={() => handleClick("reservado")}
          >
            Solicitações Aprovadas
          </a>
          <a
            className={`user-notification-side-bar--buttons ${
              solicitationType === "cancelado"
                ? "user-notification-side-bar--buttons---active"
                : null
            }`}
            onClick={() => handleClick("cancelado")}
          >
            Solicitações Canceladas
          </a>
        </div>
      </div>

      <div className="">
        <div className="notification-grid">
          {
            isLoading ? spinn : arrayOfNotifications.length > 0
                ? arrayOfNotifications
                : "Nenhuma solicitação pendente."
          }
        </div>
      </div>

      <ConfirmationModal
        handleConfirmation={cancelReservation}
        isShowing={isShowingConfirmationModal}
        titleText={"Cancelar Reserva"}
        toggle={toggleConfirmationModal}
      />
    </div>
  );
};

export default UserNotifications;
