import React, {useEffect, useState, useCallback, useContext} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
// import useModal from "../../request-modal/useModal";
import Select from "react-select";
import {
  customInvalidStyle,
  customValidatedStyle,
  customStyles,
} from "../../utils/react-select.custom-styles";

// import ActionFeedbackModal from "../../action-feedback-modal/action-feedback-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const EditSpaceModal = ({
  isShowing,
  toggle,
  spaceId,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
}) => {
  const [validated, setValidated] = useState(false);

  const [allTypesOptions, setAllTypesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [allResources, setAllResources] = useState([]);

  const [typeObj, setTypeObj] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [areaId, setAreaId] = useState(null);

  const [isAdmin, setIsAdmin] = useState(false);

  const [
    allSumariosSpaceNameOptions,
    setAllSumariosSpaceNameOptions,
  ] = useState([]);
  const [sumariosSpaces, setSumariosSpaces] = useState(null);
  const [sumariosSpaceNameId, setSumariosSpaceNameId] = useState(null);

  const [allSpaceManagers, setAllSpaceManagers] = useState(null);
  const [selectedSpaceManager, setSelectedSpaceManager] = useState(null);

  const [selectedTechnicians, setSelectedTechnicians] = useState([]);

  const [spaceManagerId, setSpaceManagerId] = useState(null);

  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [defaultResponsibleId, setDefaultResponsibleId] = useState(null);

  const [isSpaceManagerInputValid, setIsSpaceManagerInputValid] = useState(
    "null"
  );

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const [spaceFormData, setSpaceFormData] = useState({
    spaceName: "",
    spaceNumber: "",
    spaceInitials: "",
    spaceDescription: "",
    spaceLocation: "",
    spacePhoneNumber: "",
    spaceNumberOfDoors: "",
    spaceCapacity: "",
    spaceCoordinates: "",
    spaceTemplate: "",
  });

  const onHandleChange = useCallback((evt) => {
    const { value, name } = evt.currentTarget;

    setSpaceFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  });

  const [selectedResourcesIds, setSelectedResourcesIds] = useState("");
  const [selectedResourcesObj, setSelectedResourcesObj] = useState("");

  const setDefaultTechnicians = (technicians) => {
    if (technicians.length > 0) {
      const defaultTechnicians = technicians.map((technician) => {
        return { label: technician.name, value: technician._id };
      });

      setSelectedTechnicians(defaultTechnicians);
    }
  };

  useEffect(() => {
    (async () => {
      if (spaceId !== null) {
        await axios
          .get(`${API_ROUTE}space/${spaceId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            const spaceData = res.data.data.spaceData;
            setSpaceFormData({
              ...spaceFormData,
              spaceName: spaceData.name,
              spaceNumber: spaceData.number,
              spaceInitials: spaceData.initials,
              spaceDescription: spaceData.description,
              spaceLocation: spaceData.location,
              spacePhoneNumber: spaceData.phoneNumber,
              spaceNumberOfDoors: spaceData.numberOfDoors,
              spaceCapacity: spaceData.capacity,
              spaceCoordinates: spaceData.coordinates,
              spaceTemplate: spaceData.template,
            });
            setSelectedResourcesIds(spaceData.resources);
            setSumariosSpaceNameId(spaceData.spaceCodesInSumarios);
            setTypeId(spaceData.typeId);
            setSpaceManagerId(spaceData.spaceManagerId);

            setDefaultResponsibleId(spaceData.responsibleId);
            setDefaultTechnicians(spaceData.technicians);
          })
        .catch((err) => console.error(err));
      }
    })();
  }, [spaceId]);

  useEffect(() => {
    if (spaceManagerId && allSpaceManagers && allSpaceManagers.length > 0) {
      const defaultSpaceManager = allSpaceManagers.filter((spaceManager) =>
        spaceManagerId.some(
          (defaultManager) => defaultManager === spaceManager.value
        )
      );
      setSelectedSpaceManager(defaultSpaceManager);
    }
  }, [spaceManagerId, allSpaceManagers]);

  // default type value
  useEffect(() => {
    if (typeId && allTypesOptions.length > 0) {
      const defaultTypeValue = allTypesOptions.find(
        (type) => type.value === typeId
      );
      setTypeObj(defaultTypeValue);
      setTypeId(typeId);
    }
  }, [typeId, allTypesOptions]);

  useEffect(() => {
    if (defaultResponsibleId && allEmployees && allEmployees.length > 0) {
      const defaultEmployeeResponsible = allEmployees.find(
        (employee) => employee.value === defaultResponsibleId
      );
      setSelectedEmployee(defaultEmployeeResponsible);
    }
    if (defaultResponsibleId === undefined) {
      if (allEmployees && allEmployees.length > 0) {
        setSelectedEmployee(allEmployees[0]);
      }
    }
  }, [defaultResponsibleId, allEmployees]);

  useEffect(() => {
    if (sumariosSpaceNameId && allSumariosSpaceNameOptions.length > 0) {
      const defaultSumariosOption = [];
      sumariosSpaceNameId.forEach(ssid =>
        defaultSumariosOption.push(
            allSumariosSpaceNameOptions.filter((sumariosOption) => ssid === sumariosOption.value)[0])
      );
      setSumariosSpaces(defaultSumariosOption);
    }
  }, [sumariosSpaceNameId, allSumariosSpaceNameOptions]);

  const handleEmployeeChange = (selected) => {
    setSelectedEmployee(selected);
  };

  const handleChangeTechnicians = (selected) => {
    setSelectedTechnicians(selected);
  };

  const handleChangeTypeId = (selected) => {
    setTypeId(selected.value);
  };

  const handleChangeResources = (selected) => {
    setSelectedResourcesObj(selected);
  };

  const handleSpaceManagerChange = (selected) => {
    // if form submission was tried and there's a selected value then
    if (selected && isSpaceManagerInputValid === "false") {
      setIsSpaceManagerInputValid("true");
    }
    setSelectedSpaceManager(selected);
  };

  const handleChangeSumarios = (selected) => {
    setSumariosSpaces(selected);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // validates Select Space Manager Input
    if (!selectedSpaceManager || selectedSpaceManager.length === 0) {
      setIsSpaceManagerInputValid("false");
    }

    // get just the id of the resources
    let resources = [];
    if (selectedResourcesObj && selectedResourcesObj.length > 0) {
      resources = selectedResourcesObj.map((resource) => resource.value);
    }

    let technicians = [];
    if (selectedTechnicians && selectedTechnicians.length > 0) {
      technicians = selectedTechnicians.map((tech) => tech.value);
    }

    let sumariosSpacesFormatted = [];
    if (sumariosSpaces && sumariosSpaces.length > 0)
      sumariosSpacesFormatted = sumariosSpaces.map(
        (sumariosSpace) => sumariosSpace.value
      );

    if (
      selectedSpaceManager !== null &&
      selectedSpaceManager.length !== 0 &&
      form.checkValidity() === true
    ) {
      let spaceManagersFormatted = [];
      spaceManagersFormatted = selectedSpaceManager.map(
        (spaceManager) => spaceManager.value
      );

      const dataToSend = {
        name: spaceFormData.spaceName,
        number: spaceFormData.spaceNumber,
        initials: spaceFormData.spaceInitials,
        description: spaceFormData.spaceDescription,
        location: spaceFormData.spaceLocation,
        phoneNumber: spaceFormData.spacePhoneNumber,
        numberOfDoors: spaceFormData.spaceNumberOfDoors,
        capacity: spaceFormData.spaceCapacity,
        coordinates: spaceFormData.spaceCoordinates,
        template: spaceFormData.spaceTemplate,
        typeId: typeId,
        areaId,
        spaceCodesInSumarios: sumariosSpacesFormatted,
        resources,
        spaceManagerId: spaceManagersFormatted,
        responsibleId: selectedEmployee.value,
        technicians,
      };

      (async () => {
        await axios
          .put(`${API_ROUTE}space/${spaceId}`, dataToSend, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Espaço alterado com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggle();
          })
          .catch((err) => {
            if (err.response.data.message) {
              setTitleText(err.response.data.message);
            } else {
              setTitleText(
                "Algo inesperado aconteceu, tente novamente mais tarde!"
              );
            }
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggle();
          });
      })();
    } else {
      event.stopPropagation();
    }

    setValidated(true);
  };

  // get all Types and all Areas to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}type/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const allTypes = res.data.data.allTypesBySchool;

          const tmpAllTypesOptions = allTypes.map((type) => {
            return {
              value: type._id,
              label: type.name,
            };
          });
          setAllTypesOptions(tmpAllTypesOptions);
        });

      await axios
        .get(`${API_ROUTE}area/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const allAreas = res.data.data.allAreasBySchool;
          const tmpAllAreasOptions = allAreas.map((area) => {
            return {
              value: area._id,
              label: area.name,
            };
          });
          setAreaId(tmpAllAreasOptions[0]?.value);
          setAreasOptions(tmpAllAreasOptions);
        });

      await axios
        .get(`${API_ROUTE}resource/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllResources = res.data.data.allResourcesBySchool.map((resource) => {
            return {
              value: resource._id,
              label: resource.name,
              description: resource.description,
            };
          });
          if (tmpAllResources.length > 0) {
            setAllResources(tmpAllResources);
          }
        });

      await axios
        .get(`${API_ROUTE}employee`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.user.role === "system-manager" || res.data.user.role === "system-master-manager") {
            setIsAdmin(true);
          }
        });

      await axios
        .get(`${API_ROUTE}space/sumarios/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const sumariosSpaceData = res.data.data.sumariosSpaces;

          const allSumariosSpacesFormatted = sumariosSpaceData.map(
            (sumariosSpace) => {
              return {
                value: sumariosSpace.codSala,
                label: sumariosSpace.nome,
              };
            }
          );

          setAllSumariosSpaceNameOptions(allSumariosSpacesFormatted);
        });

      // get users
      await axios
        .get(`${API_ROUTE}employee/getAll/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const allEmployees = res.data.data.employees;

          const allEmployeesOptions = allEmployees.map((employee) => {
            return {
              value: employee._id,
              label: `${employee.name} (${employee.email.split("@")[0]})`,
            };
          });

          setAllEmployees(allEmployeesOptions);

          const spaceManagers = allEmployees
            .filter((employee) => employee.role === "space-manager")
            .map((spaceManager) => {
              return { value: spaceManager._id, label: `${spaceManager.name} (${spaceManager.email.split("@")[0]})` };
            });

          setAllSpaceManagers(spaceManagers);
        });
    })();
  }, [setSelectedSchoolCode]);

  useEffect(() => {
    // if the space has no resource
    if (selectedResourcesIds.length === 0) {
      setSelectedResourcesObj(null);
    }
    if (selectedResourcesIds.length > 0 && allResources.length > 0) {
      const defaultValue = allResources.filter((val) => {
        return selectedResourcesIds.includes(val.value);
      });
      setSelectedResourcesObj(defaultValue);
    }
  }, [selectedResourcesIds, allResources]);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Editar Espaço</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome do Espaço"
                    value={spaceFormData.spaceName}
                    onChange={onHandleChange}
                    name="spaceName"
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de um nome!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Tipo</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o tipo do espaço"
                    options={allTypesOptions}
                    value={typeObj}
                    onChange={handleChangeTypeId}
                  />
                </Form.Group>

                {isAdmin ? (
                  <Form.Group controlId="validationCustomFloor">
                    <Form.Label>Gestor do Espaço</Form.Label>

                    <Select
                      styles={
                        isSpaceManagerInputValid === "true"
                          ? customValidatedStyle
                          : isSpaceManagerInputValid === "false"
                          ? customInvalidStyle
                          : customStyles
                      }
                      isMulti
                      placeholder="Selecione ou digite o nome do gestor do espaço"
                      options={allSpaceManagers}
                      value={selectedSpaceManager}
                      onChange={handleSpaceManagerChange}
                    />
                  </Form.Group>
                ) : null}

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Responsável</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome do responsável"
                    options={allEmployees}
                    value={selectedEmployee}
                    onChange={handleEmployeeChange}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Técnicos</Form.Label>
                  <Select
                    isMulti
                    placeholder="Selecione ou digite o(s) nome(s) do(s) técnico(s)"
                    options={allEmployees}
                    onChange={handleChangeTechnicians}
                    value={selectedTechnicians}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>
                    Nome do Espaço nos Sumários (se houver)
                  </Form.Label>
                  <Select
                    isMulti
                    isClearable={true}
                    placeholder="Selecione ou digite o nome do espaço nos sumários"
                    options={allSumariosSpaceNameOptions}
                    value={sumariosSpaces}
                    onChange={handleChangeSumarios}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Número do Espaço"
                    name="spaceNumber"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de um número!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Abreviatura</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Abreviatura do Espaço"
                    name="spaceInitials"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceInitials}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma abreviatura!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Descrição do Espaço"
                    name="spaceDescription"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceDescription}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma descrição!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Localização</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Localização do Espaço"
                    name="spaceLocation"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceLocation}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma Localização!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Extensão Telefónica</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Extensão Telefónica"
                    name="spacePhoneNumber"
                    onChange={onHandleChange}
                    value={spaceFormData.spacePhoneNumber}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Número de Portas</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Número de Portas"
                    name="spaceNumberOfDoors"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceNumberOfDoors}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de um número de portas!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomName">
                  <Form.Label>Capacidade</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Capacidade do Espaço"
                    name="spaceCapacity"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceCapacity}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de uma Capacidade!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomFloor">
                  <Form.Label>Recursos</Form.Label>
                  <Select
                    isMulti
                    placeholder="Selecione ou digite o tipo do espaço"
                    options={allResources}
                    onChange={handleChangeResources}
                    value={selectedResourcesObj}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Coordernadas</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coordenadas do Espaço"
                    required
                    name="spaceCoordinates"
                    onChange={onHandleChange}
                    value={spaceFormData.spaceCoordinates}
                  />
                  <Form.Control.Feedback type="invalid">
                    O espaço precisa de Coordernadas!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustomTemplate">
                  <Form.Label>Template</Form.Label>
                  <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      placeholder="Template da Descrição de Reserva"
                      name="spaceTemplate"
                      onChange={onHandleChange}
                      value={spaceFormData.spaceTemplate}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default EditSpaceModal;
